import React from "react";
import Header from "./header";
import { IconContext } from "react-icons";
import Helmet from "react-helmet";
import favicon from "../assets/favicon.png";

import "../styles/layout.css";
import styles from "./layout.module.css";

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    <IconContext.Provider value={{ color: "black", size: 30, className: "custom-icon" }}>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
      <div className={styles.content}>{children}</div>
    </IconContext.Provider>
  </>
);

export default Layout;
